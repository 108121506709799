<template>
    <div>
        <ek-table
            :items="teacherList"
            :columns="TeacherCols"
            @details="goToDetails"
            @delete-selected="deleteTeachers"
            deleteBtn
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : '' }}
            </template>

            <template slot="items.isBlocked" slot-scope="{ value }">
                <StatusBadge :value="value" :options="statusTeacherList" />
            </template>
            
           
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import StatusBadge from "@global/components/StatusBadge.vue";

export default {
    components: {
        StatusBadge
    },
    computed: {
        ...mapState({
            teachers: ({ teachers }) => teachers.teachers,
            TeacherCols: ({ teachers }) => teachers.TeacherCols,
            statusTeacherList:({ teachers }) => teachers.statusTeacher
        }),
        ...mapGetters(['teacherList'])
    },
    data: () => ({

    }),
    methods: {
        ...mapActions(['getTeachersList', 'deleteTeachers']),
        goToDetails({ row }) {
            let rowDotName = row.name
            this.$router.push({ path: `teachers/${row.id}`, query: { name: rowDotName } });
        },
    },
    created() {
        this.getTeachersList();
    },
};
</script>